html,
body {
  min-height: 100vh;
  background-color: var(--slate-100);
  letter-spacing: 0.08px;
  color: var(--grey-800);
}

html.cdk-global-scrollblock {
  overflow: auto;

  mat-toolbar {
    position: fixed !important;
    width: 100vw;
    padding-right: 12px;
  }

  #app-content-wrapper:not(.no-header) {
    padding-top: 64px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
}

a {
  color: var(--grey-800);
  text-decoration: none;
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity;
  text-decoration-line: none;
}

a:hover {
  text-decoration: none;
}

body {
  font-family: inter, "inter", sans-serif !important;
  font-size: 14px;
  line-height: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 0px;
  line-height: 1.3;
  color: var(--grey-800);
}

h1,
.h1 {
  font-size: calc(25px + 2vmin);

  //font-size: calc(22px + 1.5vmin);

  //   font-size: calc(12px + 3vmin);
  //   font-size: 40px;
}

h2,
.h2 {
  font-size: calc(19px + 1.5vmin);
  // // font-size: calc(9px + 3vmin);
  // font-size: 32px;
}

h3,
.h3 {
  font-size: calc(13px + 1.5vmin);
  // font-size: calc(3px + 3vmin);
  // font-size: 28px;
  font-weight: 500;
}

h4,
.h4 {
  font-size: calc(9px + 1.5vmin);
  // font-size: 24;
  font-weight: 500;
}

h5,
.h5 {
  font-size: calc(10px + 1.2vmin);
  // font-size: 20;
  font-weight: 400;
}

h6,
.h6 {
  font-size: calc(8px + 1.2vmin);
  font-weight: 600;
}

p {
  font-size: 14px;
  margin: 0;
  line-height: 1.5;
}

input[type="radio"] {
  margin: 0px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

hr {
  height: 0.05rem !important;
  color: var(--grey-200);
  opacity: 1;
}

img,
a {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

::-webkit-scrollbar {
  height: 12px;
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background: var(--grey-200);
  -webkit-border-radius: 1ex;
}
