.mat-mdc-tooltip {
  white-space: pre-line !important;

  .mdc-tooltip__surface {
    max-width: 250px;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 20px;
    text-align: start !important;
    background-color: var(--grey-800) !important;
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.37);
    overflow: auto !important;
  }

  &.mdc-tooltip--multiline .mdc-tooltip__surface {
    text-align: start;
    white-space: pre-line !important;
    max-height: 400px;
  }
}

[mattooltip] {
  touch-action: auto !important;
}

.mat-mdc-tooltip-trigger {
  touch-action: auto !important;
}
