/* You can add global styles to this file, and also import other style files */
@import "material/material";
@import "html";
@import "layout";
@import "core-styles";
@import "find-work-styles";
@import "margins";
@import "paddings";
@import "buttons-old";
@import "background-colors";
@import "cards";
@import "fonts";
@import "buttons";
@import "sales-iq-styles";
@import "autocomplete";
@import "borders";
@import "utils/utils";
@import "tours";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "colors";

:root {
  --spacing-s: 12px;
  --spacing-m: 16px;
  --spacing-l: 20px;
  --spacing-xl: 24px;
}

:root {
  @each $color-name, $color-value in $dark-colors {
    --#{$color-name}: #{$color-value};
  }

  @each $color-name, $color-value in $light-colors {
    --#{$color-name}: #{$color-value};
  }
}

.clear-button {
  cursor: pointer;
  align-self: center;

  img {
    opacity: 0.4;
    width: 16px;
    -webkit-transition: 0.2s opacity;
    transition: 0.2s opacity;
    display: block;

    &:hover {
      opacity: 1;
    }
  }
}

.section-divider {
  display: flex;
  align-items: center;
  flex: 1;

  h2 {
    margin: 0;
  }

  hr {
    flex: 1;
    margin-left: 20px;
  }
}

.input-wrapper-fullscreen {
  width: 100%;
  padding: 10px;
}

svg[ds-icon="true"] {
  path {
    stroke-width: 1.5;
  }
}
