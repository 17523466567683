@mixin button-tabs($color: var(--grey-800)) {
  .mat-mdc-tab-header {
    display: flex !important;
    border: 0;

    .mat-mdc-tab-label-container {
      justify-content: center;

      .mat-mdc-tab-list {
        flex-grow: initial;

        .mat-mdc-tab-labels {
          padding: 6px;
          gap: 10px;
          background-color: var(--white);
          border: 1px solid var(--grey-200);
          border-radius: 10px;

          .mat-mdc-tab {
            position: relative;
            height: auto;
            min-width: auto;
            border-radius: 10px;
            font-size: 14px;
            font-weight: 500;
            opacity: 1;
            overflow: initial;
            transition: all 200ms;
            width: 100px;
            padding: 10px;

            &.mdc-tab--active {
              background-color: var(--grey-800);

              .mdc-tab__text-label {
                color: white;
              }
            }

            .discount-arrow {
              position: absolute;
              top: 0px;
              right: 0;
              transform: translateX(calc(-100% + 30px))
                translateY(calc(-100% + 14px));
              display: flex;
              flex-direction: column;
              font-size: 14px;
              color: var(--blue-600);

              img {
                align-self: flex-end;
              }
            }
          }
        }
      }
    }
  }
}

.mdc-tab-indicator {
  display: none !important;
}
