@import "layout.scss";
@import "colors.scss";

// font spacing and font weight on macos
html {
  font-optical-sizing: none;
  -webkit-font-smoothing: antialiased;
}

$fonts: 8, 10, 12, 13, 14, 16, 18, 20, 22, 24, 28, 30, 32, 36, 40, 48, 50, 60;

@each $font in $fonts {
  .f-#{$font} {
    font-size: #{$font}px;
    @if $font == 12 {
      line-height: 16px;
    } @else if $font == 14 {
      line-height: 20px;
    } @else if $font == 16 {
      line-height: 24px;
    }
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @media only screen and (min-width: $breakpoint) {
    @each $font in $fonts {
      .f-#{$breakpoint-name}-#{$font} {
        font-size: #{$font}px;
      }
    }
  }
}

$font-colors: (
  "sky-800",
  "neutral-500",
  "violet-600",
  "teal-500",
  "teal-500",
  "white",
  "red-500",
  "grey-800",
  "neutral-400",
  "slate-600",
  "amber-500",
  "blue-600",
  "fuchsia-500",
  "red-500",
  "grey-400",
  "grey-500",
  "grey-600",
  "red-600",
  "neutral-700",
  "slate-500",
  "pink-500"
);

@each $color-name in $font-colors {
  .f-#{$color-name} {
    color: #{map-get($colors, $color-name)} !important;
  }
}

$font-weights: normal, bold, bolder, lighter, boldest, lightest;

@each $weight in $font-weights {
  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

@for $i from 1 through 10 {
  $weight: $i * 100;

  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }
}

.f-italic {
  font-style: italic;
}

.f-center {
  text-align: center;
}

.f-left {
  text-align: left;
}

.f-right {
  text-align: right;
}

.f-underline {
  text-decoration: underline;
}

.f-hover {
  &:hover {
    color: #{map-get($colors, "grey-800")};
  }
}

.f-signature {
  font-family: "Nothing You Could Do";
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
}

.text-sentence-case {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.small-p {
  font-size: calc(11px + 0.8vmin);
  line-height: calc(17px + 1vmin);
}

.very-small-p {
  font-size: calc(11px + 0.5vmin);
  line-height: calc(14px + 1.2vmin);
}

.extra-small-p {
  font-size: calc(6px + 0.8vmin);
  line-height: calc(10px + 1.5vmin);
  font-weight: normal;
}

.lh-normal {
  line-height: normal;
}

@font-face {
  font-family: "Roboto";
  src:
    url("/assets/fonts/Roboto/Roboto-Regular.ttf"),
    url("/assets/fonts/Roboto/Roboto-Regular.ttf?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Verdana";
  src:
    url("/assets/fonts/Verdana/Verdana.ttf"),
    url("/assets/fonts/Verdana/Verdana.ttf?#iefix") format("embedded-opentype"),
    url("/assets/fonts/Verdana/Verdana.ttf") format("truetype");
}
