@import "layout";

:root {
  --margin-xs: 7px;
  --margin-s: 10px;
  --margin-m: 15px;
  --margin-l: 25px;
  --marign-xl: 35px;
}

$margins: 0, 2, 4, 6, 8, 10, 12, 15, 16, 18, 20, 24, 28, 30, 32, 36, 40, 44, 48,
  52, 56, 60, 80, 90, 160;
$breakpoints: (
  "xs": $breakpoint-xs,
  "sm": $breakpoint-sm,
  "md": $breakpoint-md,
  "lg": $breakpoint-lg,
  "xl": $breakpoint-xl,
);

.margin-childs {
  > * {
    margin: 20px 0;
  }
}

.margin-childs-s {
  > * {
    margin: 7px 0;
  }
}

.margin-childs-vertical-xs {
  > *:not(:last-child) {
    margin-bottom: var(--margin-xs);
  }
}

.margin-childs-vertical-s {
  > *:not(:last-child) {
    margin-bottom: var(--margin-s);
  }
}

.margin-childs-vertical {
  > *:not(:last-child) {
    margin-bottom: 18px;
  }
}

.margin-childs-vertical-l {
  > *:not(:last-child) {
    margin-bottom: var(--spacing-l);
  }
}

.margin-childs-vertical-xl {
  > *:not(:last-child) {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: $breakpoint-md) {
  .margin-childs-vertical-md-0 {
    > *:not(:last-child) {
      margin-bottom: 0;
    }
  }

  .margin-childs-vertical-md-1 {
    > *:not(:last-child) {
      margin-bottom: var(--margin-xs);
    }
  }

  .margin-childs-vertical-md-2 {
    > *:not(:last-child) {
      margin-bottom: var(--margin-s);
    }
  }

  .margin-childs-vertical-md-3 {
    > *:not(:last-child) {
      margin-bottom: var(--margin-m);
    }
  }

  .margin-childs-vertical-md-4 {
    > *:not(:last-child) {
      margin-bottom: var(--margin-l);
    }
  }

  .margin-childs-vertical-md-5 {
    > *:not(:last-child) {
      margin-bottom: var(--margin-xl);
    }
  }
}

.margin-childs-horizontal-1 {
  > *:not(:last-child) {
    margin-right: 24px;
  }
}

.margin-childs-horizontal {
  > *:not(:last-child) {
    margin-right: 18px;
  }
}

.margin-childs-horizontal-s {
  > *:not(:last-child) {
    margin-right: 10px;
  }
}

@each $margin in $margins {
  .margin-childs-horizontal-#{$margin} {
    > *:not(:last-child) {
      margin-right: #{$margin}px;
    }
  }

  .margin-childs-vertical-#{$margin} {
    > *:not(:last-child) {
      margin-bottom: #{$margin}px;
    }
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @media only screen and (min-width: $breakpoint) {
    @each $margin in $margins {
      .margin-childs-horizontal-#{$breakpoint-name}-#{$margin} {
        > *:not(:last-child) {
          margin-right: #{$margin}px;
        }
      }

      .margin-childs-vertical-#{$breakpoint-name}-#{$margin} {
        > *:not(:last-child) {
          margin-bottom: #{$margin}px;
        }
      }
    }
  }
}

.mg-auto {
  margin: auto !important;
}

.mg-block-auto {
  margin-block: auto !important;
}

.mg-inline-auto {
  margin-inline: auto !important;
}

.mg-b-auto {
  margin-bottom: auto !important;
}

.mg-t-auto {
  margin-top: auto !important;
}

.mg-r-auto {
  margin-right: auto !important;
}

.mg-l-auto {
  margin-left: auto !important;
}

@each $margin in $margins {
  .mg-#{$margin} {
    margin: #{$margin}px !important;
  }

  .mg-block-#{$margin} {
    margin-block: #{$margin}px !important;
  }

  .mg-inline-#{$margin} {
    margin-inline: #{$margin}px !important;
  }

  .mg-b-#{$margin} {
    margin-bottom: #{$margin}px !important;
  }

  .mg-t-#{$margin} {
    margin-top: #{$margin}px !important;
  }

  .mg-r-#{$margin} {
    margin-right: #{$margin}px !important;
  }

  .mg-l-#{$margin} {
    margin-left: #{$margin}px !important;
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @media only screen and (min-width: $breakpoint) {
    @each $margin in $margins {
      .mg-#{$breakpoint-name}-#{$margin} {
        margin: #{$margin}px !important;
      }

      .mg-b-#{$breakpoint-name}-#{$margin} {
        margin-bottom: #{$margin}px !important;
      }

      .mg-t-#{$breakpoint-name}-#{$margin} {
        margin-top: #{$margin}px !important;
      }

      .mg-r-#{$breakpoint-name}-#{$margin} {
        margin-right: #{$margin}px !important;
      }

      .mg-l-#{$breakpoint-name}-#{$margin} {
        margin-left: #{$margin}px !important;
      }

      .mg-block-#{$breakpoint-name}-#{$margin} {
        margin-block: #{$margin}px !important;
      }

      .mg-inline-#{$breakpoint-name}-#{$margin} {
        margin-inline: #{$margin}px !important;
      }
    }
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @media only screen and (min-width: $breakpoint) {
    .mg-#{$breakpoint-name}-auto {
      margin: auto !important;
    }

    .mg-b-#{$breakpoint-name}-auto {
      margin-bottom: auto !important;
    }

    .mg-t-#{$breakpoint-name}-auto {
      margin-top: auto !important;
    }

    .mg-r-#{$breakpoint-name}-auto {
      margin-right: auto !important;
    }

    .mg-l-#{$breakpoint-name}-auto {
      margin-left: auto !important;
    }

    .mg-block-#{$breakpoint-name}-auto {
      margin-block: auto !important;
    }

    .mg-inline-#{$breakpoint-name}-auto {
      margin-inline: auto !important;
    }
  }
}
