@import "scroll";

.justify-center-panel {
  .mat-mdc-menu-item {
    justify-content: center !important;
  }
}

.cdk-overlay-pane {
  margin-top: 15px;
}

.cdk-overlay-pane > .mat-mdc-menu-panel {
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-weight: 500;
  --mat-app-elevation-shadow-level-8: 0 4px 3px 0 rgba(0, 0, 0, 0.1),
    0 10px 8px 0 rgba(0, 0, 0, 0.04);
  --mat-app-elevation-shadow-level-9: 0 4px 3px 0 rgba(0, 0, 0, 0.1),
    0 10px 8px 0 rgba(0, 0, 0, 0.04);

  min-height: unset;
  max-height: 75vh;
  max-width: 100%;
  background-color: white;
  border: solid 1px var(--grey-200);
  border-radius: 12px;
  overflow: visible;
  overflow-y: auto;
  @include thin-scrollbar();

  .mat-mdc-menu-content {
    padding: 6px;

    .mat-mdc-menu-item {
      width: 100%;
      min-height: unset;
      padding: 8px 12px;
      margin-inline: auto;
      display: flex;
      align-items: center;
      border-radius: 8px;
      border: 2px solid transparent;

      mat-icon {
        margin: 0;
        display: flex;
      }

      app-svg-icon mat-icon {
        height: unset;
        width: unset;
      }

      img {
        width: 13px;
      }

      .mdc-list-item__primary-text,
      .mat-mdc-menu-item-text {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}
