@import "../buttons";

@media only screen and (min-width: $breakpoint-xl) {
  .mat-mdc-button:hover:not(.mat-mdc-button-disabled)
    .mat-mdc-button-focus-overlay,
  .mat-mdc-stroked-button:hover:not(.mat-mdc-button-disabled)
    .mat-mdc-button-focus-overlay {
    opacity: 0.1 !important;
  }
}

.mdc-button.mat-mdc-button {
  border-radius: 10px !important;
  // display: flex;
  // align-items: flex-end;
  // justify-content: center;
  --mdc-text-button-label-text-color: white;
  font-weight: 500;
  border-width: 0;
  letter-spacing: 0.5px;
  height: fit-content;
  padding: 12px 16px !important;
  @extend .btn-bk-black;
  @extend .btn-var-width;
  font-size: 14px;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition:
    background-color 300ms linear,
    transform 150ms ease-in;

  .mdc-button__label {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  line-height: 1.4 !important;

  app-svg-icon,
  app-loading-mini,
  img {
    margin-right: 10px;
  }

  &:not(.disable-scaling):hover {
    transform: scale(1.05);
  }
}
