.form-field-label {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: var(--grey-800);
  margin-bottom: 10px;
  height: 14.5px;

  .error {
    color: var(--red-500);
  }
}

.form-field-description {
  font-family: Inter, serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.07px;
  color: var(--grey-400);
  margin-bottom: 10px;
  height: 14.5px;
}

.mat-mdc-form-field.light-input .mat-mdc-text-field-wrapper {
  background-color: white !important;
}

.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic
  .mdc-evolution-chip__graphic {
  padding-left: 2px !important;
}

.mat-mdc-standard-chip {
  background-color: white !important;
  border: 1px solid var(--grey-200);
}

.mat-mdc-chip-set .mdc-evolution-chip-set__chips {
  margin: 0px !important;
}

.mdc-evolution-chip-set__chips {
  align-items: center;
  gap: 8px;
  margin: 0;

  mat-chip-row {
    background-color: white !important;
    border: 1px solid var(--grey-200);
  }
}

.mat-mdc-form-field {
  .mat-mdc-text-field-wrapper {
    border-radius: 8px !important;
    background-color: var(--grey-100);

    &:not(.mdc-text-field--disabled):hover {
      background-color: var(--grey-200);
    }

    &:focus-within {
      background-color: white;
    }
  }

  &.disabled-input .mat-mdc-text-field-wrapper {
    &:hover,
    &:focus-within {
      background-color: var(--grey-100);
    }
  }
}

.mat-mdc-form-field {
  width: 100%;
  --mdc-typography-body1-font-size: 10px;
  --mat-form-field-container-text-size: 10px;
  //--mat-form-field-container-text-weight: 500; // weight of value inside input
  //--mat-select-trigger-text-weight: 500; // weight of value inside mat select
  --mdc-outlined-text-field-outline-color: transparent;
  --mdc-outlined-text-field-focus-outline-color: var(--button-and-links-color);
  --mdc-outlined-text-field-hover-outline-color: transparent;
  --mat-select-enabled-trigger-text-color: var(--color);

  ////////////////////////////////////////////////////////////////////////////////

  --mat-form-field-container-vertical-padding: 10px;
  --mat-form-field-container-height: 46px;

  &.size {
    &-small {
      --mat-form-field-container-vertical-padding: 6px;
      --mat-form-field-container-height: 38px;
    }

    &-large {
      --mat-form-field-container-vertical-padding: 18px;
      --mat-form-field-container-height: 62px;
    }
  }

  &.skilled-border {
    --button-and-links-color: var(--teal-500);
  }

  &.customer-border {
    --button-and-links-color: var(--blue-600);
  }

  ///////////////////////////////////////////////////////////////////////////////
}

// affecting value and placeholder of text-input
.mat-mdc-input-element {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: var(--grey-500) !important;
  font-weight: 400;
  letter-spacing: normal !important;
}

.mat-mdc-select-placeholder {
  color: var(--grey-500) !important;
  font-weight: 500;
}

.mat-mdc-form-field-infix {
  width: auto !important;
  padding-top: var(--mat-form-field-container-vertical-padding) !important;
  padding-bottom: var(--mat-form-field-container-vertical-padding) !important;
  min-height: auto;
}

.mat-mdc-form-field-icon-suffix,
.mat-mdc-form-field-icon-prefix {
  display: flex;
  padding: 0 8px !important;

  > * {
    padding: 6px;
  }
}

.mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mdc-text-field .mdc-floating-label {
  --mat-mdc-form-field-label-offset-x: -2px;
  color: var(--grey-800) !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 26px !important;
  font-weight: 500;
}

.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  max-width: 140% !important;
}

.mat-mdc-form-field-error,
.mat-mdc-form-field-hint {
  display: block;
  white-space: pre-line;
  line-height: 18px;
  font-size: 14px;
  margin-top: 4px;
  font-weight: 300;

  &.mat-mdc-form-field-error {
    color: var(--red-500);
  }

  &.mat-mdc-form-field-hint {
    color: var(--grey-400);

    &.outsider {
      position: inherit;
      top: -2px;
    }
  }
}

.mdc-notched-outline .mdc-notched-outline__leading {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.mdc-notched-outline .mdc-notched-outline__trailing {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.mdc-text-field--disabled {
  .mdc-notched-outline
    :is(
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing
    ) {
    border-color: transparent !important;
  }

  .mdc-text-field__input,
  mat-label {
    color: var(--neutral-300) !important;
  }
}

.mat-mdc-form-field.without-wrapper {
  .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: 0 !important;
    border: none !important;
  }

  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    background-color: transparent !important;
  }

  .mdc-notched-outline > * {
    border: none !important;
  }

  .mdc-checkbox__background {
    border-radius: 20px !important;
  }
}
