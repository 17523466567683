@import "colors";
@import "background-colors";

@each $color-name, $color-value in $colors {
  .card-#{$color-name} {
    @extend .bg-#{$color-name};
    border-radius: 10px;
    border: 1px solid var(--grey-200);
  }
}

@mixin card {
  background-color: white;
  padding: 30px 50px;
  border-radius: 20px;
  overflow: hidden;
  @extend .margin-childs-vertical-30;
}

.form-card {
  @include card;
  border: 1px solid var(--grey-200);
}

.form-card-shadow {
  @include card;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}
