.mat-mdc-radio-button .mdc-label {
  padding-right: 8px !important;
  padding-left: 8px !important;
  font-weight: 400 !important;
}

.mat-mdc-radio-group.tile-radio-group {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  justify-content: space-around;
  border: 1px solid var(--grey-200);
  border-radius: 4px;

  .mat-mdc-radio-button {
    display: flex;
    flex: 1;

    &:not(:last-child) {
      border-right: 1px solid var(--grey-200);
    }

    &:hover:not(.mat-mdc-radio-checked) {
      background-color: var(--grey-50);
    }

    .mdc-form-field {
      flex: 1;

      .mdc-label {
        min-height: 30px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        margin: 5px;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }

  .mat-mdc-radio-checked .mdc-label {
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.22);
    background-color: var(--teal-500);
    color: white;
  }
}

.sf-mat-radio {
  input[type="checkbox"] {
    margin: 0px 0 0;
    margin-top: 1px \9;
    line-height: normal;
  }

  .mdc-radio {
    display: none !important;
  }

  .mdc-radio__outer-circle {
    height: 0px;
    position: absolute;
    transition: border-color transparent 280ms;
    width: 0px;
    border-width: 0px;
    border-style: none;
    border-radius: 50%;
  }

  .mat-mdc-radio-button.mat-accent {
    .mdc-radio__inner-circle {
      background-color: transparent;
    }

    .mat-radio-ripple .mat-ripple-element {
      background-color: transparent;
    }
  }

  .mat-mdc-radio-button.mat-accent.mat-mdc-radio-checked {
    // .mdc-radio__outer-circle {
    display: none;
    border-color: transparent;
  }
}
