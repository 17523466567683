.mat-calendar-body-selected {
  background-color: var(--grey-800);
}

.mat-calendar-arrow {
  fill: white;
}

.mat-calendar {
  --mat-datepicker-calendar-date-selected-state-background-color: var(
    --grey-800
  );
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(
    --grey-400
  );
  --mat-datepicker-calendar-date-hover-state-background-color: var(--zinc-200);
}
