@mixin hide-scrollbar {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}

@mixin thin-scrollbar($scroll-bar-width: 5px, $background: var(--grey-200), $opacityOnLeave: 0) {
  &::-webkit-scrollbar {
    height: $scroll-bar-width !important;
    width: $scroll-bar-width !important;
    transition: opacity 5s ease-in-out;
    opacity: $opacityOnLeave;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: $background;
    opacity: 1;
  }

  &::-webkit-scrollbar-thumb {
    @if $opacityOnLeave ==0 {
      background: transparent;
    }
  }
}

@mixin overflow-horizontal {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  flex-wrap: nowrap;
  @include hide-scrollbar();
}
