@import "colors";

@each $color-name, $color-value in $light-colors {
  .bg-#{$color-name} {
    background-color: $color-value !important;
    color: var(--grey-800) !important;
  }
}

@each $color-name, $color-value in $dark-colors {
  .bg-#{$color-name} {
    background-color: $color-value !important;
    color: white !important;
  }
}
