@mixin icon-color($color) {
  svg {
    path:not(path[fill="none"]) {
      fill: $color !important;
    }

    path[stroke]:not(path[stroke="none"]) {
      stroke: $color !important;
    }

    circle:not(circle[fill="none"]) {
      fill: $color !important;
    }

    rect:not(rect[fill="none"]) {
      fill: $color !important;
    }
  }
}

@mixin icon-fill-color($color) {
  svg {
    path:not(path[fill="none"]) {
      fill: $color !important;
    }

    circle:not(circle[fill="none"]) {
      fill: $color !important;
    }

    rect:not(rect[fill="none"]) {
      fill: $color !important;
    }
  }
}

@mixin icon-stroke-color($color) {
  svg {
    path[stroke]:not(path[stroke="none"]) {
      stroke: $color !important;
    }

    circle[stroke]:not(circle[stroke="none"]) {
      fill: $color !important;
    }

    rect[stroke]:not(rect[stroke="none"]) {
      fill: $color !important;
    }
  }
}
