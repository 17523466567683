@import "layout";

.core-btn {
  width: fit-content;
  border-radius: 10px !important;
  // box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 19px 0 rgba(0, 0, 0, 0.16);
  // font-size: calc(11px + 0.8vmin);
  font-size: 16px;
  color: white;
  background-color: var(--sky-800);
  text-align: center;
  font-weight: 500;
  border-width: 0;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;

  ::ng-deep mat-button-wrapper {
    display: flex;
    align-items: center;
  }

  span {
    line-height: 1;
  }

  app-svg-icon,
  img {
    margin-right: 10px;
  }

  mat-icon {
    path:not(path[fill="none"]) {
      fill: white;
    }
  }
}

.sky-800-btn {
  @extend .core-btn;
  padding: 3px 30px !important;

  &:hover {
    color: white;
    background-color: var(--teal-500);
  }

  &-green {
    @extend .sky-800-btn;
    background-color: var(--teal-500);
  }
}

.secondary-btn {
  @extend .core-btn;
  background-color: var(--teal-500);
  padding: 5px 20px !important;
  font-size: 16px;

  &-small-f {
    @extend .secondary-btn;
    font-size: 16px;
  }

  &-green {
    @extend .secondary-btn;
    background-color: var(--teal-500);
  }
}

.teal-500-btn {
  @extend .core-btn;
  background-color: var(--teal-500);
  padding: 10px 30px !important;
  font-size: 20px;

  &-blue {
    @extend .teal-500-btn;
    background-color: var(--sky-800);
  }
}

.disable-btn {
  @extend .core-btn;
  background-color: var(--grey-50);
  color: var(--teal-500) !important;
  padding: 10px 30px !important;
  font-size: 20px;
  box-shadow: none;
}

.amber-500-btn {
  @extend .core-btn;
  font-size: 18px;
  padding: 15px 30px !important;
  background-color: var(--amber-500);

  &:hover {
    color: var(--grey-800);
    background-color: white;
  }
}

.largest-btn {
  @extend .core-btn;
  padding: 14px 45px !important;
  font-size: 26px;
}

.outlined-btn {
  @extend .sky-800-btn;
  background-color: transparent;
  border: 1px solid var(--teal-500) !important;
  color: var(--teal-500);
  box-shadow: none;
}

.text-btn {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  color: var(--grey-800);

  app-svg-icon {
    margin-right: 10px;

    mat-icon {
      path {
        fill: var(--grey-800);
      }
    }
  }
}

.text-underline-btn {
  @extend .text-btn;
  font-weight: 500;
  color: var(--grey-800);

  span {
    text-decoration: underline;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .sky-800-btn {
    font-size: 14px;
  }

  .amber-500-btn {
    font-size: 14px;
    padding: 5px 30px !important;
  }

  .largest-btn {
    font-size: 20px;
    padding: 14px 45px !important;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .largest-btn {
    font-size: 16px;
    padding: 8px 30px !important;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-text {
  cursor: text !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.select-none {
  user-select: none;
}

.highlight {
  background-color: #ffda99;
}

.wd-100 {
  width: 100%;
}

.w-fit {
  width: fit-content;
}

.page-header {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.page-sub-header {
  color: var(--grey-500);
  font-size: 14px;
}
