@mixin card-shadow {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
}

@mixin primary-shadow {
  box-shadow: 0 0 22px 0 rgba(0, 68, 255, 0.25);
}
@mixin primary-hover-shadow {
  transition: 300ms box-shadow;
  &:hover {
    box-shadow: 0 0 22px 0 rgba(0, 68, 255, 0.25);
  }
}

@mixin hover-shadow {
  &:hover {
    box-shadow: 0 1px 15px rgb(57 73 76 / 40%);
  }
}

@mixin light-hover-shadow {
  &:hover {
    box-shadow: 0 1px 7px rgba(57, 73, 76, 0.4);
  }
}
