@import "layout";

$paddings: 0, 2, 4, 6, 8, 10, 12, 14, 16, 20, 24, 30, 40, 50, 60, 90;

@each $padding in $paddings {
  .pd-#{$padding} {
    padding: #{$padding}px;
  }

  .pd-b-#{$padding} {
    padding-bottom: #{$padding}px;
  }

  .pd-t-#{$padding} {
    padding-top: #{$padding}px;
  }

  .pd-r-#{$padding} {
    padding-right: #{$padding}px;
  }

  .pd-l-#{$padding} {
    padding-left: #{$padding}px;
  }

  .pd-block-#{$padding} {
    padding-block: #{$padding}px;
  }

  .pd-inline-#{$padding} {
    padding-inline: #{$padding}px;
  }
}

@each $breakpoint-name, $breakpoint in $breakpoints {
  @media only screen and (min-width: $breakpoint) {
    @each $padding in $paddings {
      .pd-#{$breakpoint-name}-#{$padding} {
        padding: #{$padding}px;
      }

      .pd-b-#{$breakpoint-name}-#{$padding} {
        padding-bottom: #{$padding}px;
      }

      .pd-t-#{$breakpoint-name}-#{$padding} {
        padding-top: #{$padding}px;
      }

      .pd-r-#{$breakpoint-name}-#{$padding} {
        padding-right: #{$padding}px;
      }

      .pd-l-#{$breakpoint-name}-#{$padding} {
        padding-left: #{$padding}px;
      }

      .pd-block-#{$breakpoint-name}-#{$padding} {
        padding-block: #{$padding}px;
      }

      .pd-inline-#{$breakpoint-name}-#{$padding} {
        padding-inline: #{$padding}px;
      }
    }
  }
}

.section-padding {
  padding-block: 60px;

  @media screen and (max-width: $breakpoint-md) {
    padding-block: 30px;
  }
}

.section-padding-m {
  padding-block: 120px;

  @media screen and (max-width: $breakpoint-md) {
    padding-block: 60px;
  }
}

.section-padding-l {
  padding-block: 200px;

  @media screen and (max-width: $breakpoint-md) {
    padding-block: 100px;
  }
}
