@import "box-shadow";
@import "layout";

// .zsiq_custommain {
// widget position
.zsiq_floatmain {
  top: 50%;
  transform: translateY(-50%);
  right: 0 !important;
  border-radius: 10px 0 0 10px;
  z-index: 10 !important;
  @include card-shadow;

  &.zsiq_theme11 {
    // widget background color
    .zsiq_flt_rel {
      background-color: var(--teal-50) !important;

      &::before,
      &::after {
        border-color: var(--teal-50) !important;
      }

      .siqico-chat {
        // widget icon
        &::before {
          color: var(--teal-500) !important;
          font-size: 30px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    top: unset;
    bottom: 130px !important;
  }
}
