@use "@angular/material" as mat;

@import "@angular/material/prebuilt-themes/indigo-pink.css";

@import "material/checkbox";
@import "material/select";
@import "material/slide-toggle";
@import "material/tooltip";
@import "material/input";
@import "material/radio";
@import "material/form-field";
@import "material/dialog";
@import "material/cdk";
@import "material/drag";
@import "material/button";
@import "material/calendar";
@import "material/menu";
@import "material/tabs";
@import "material/progress-bar";

@include mat.core();

$custom-typography: mat.m2-define-typography-config(
  $font-family: "inter",
);
$my-theme: mat.m2-define-light-theme(
  (
    typography: $custom-typography,
  )
);
@include mat.all-component-themes($my-theme);

[matripple] {
  touch-action: auto !important;
}

:root {
  --mdc-theme-primary: var(--grey-800);
}

.mat-drawer-backdrop {
  top: 56px !important;
}
