.autocomplete-mob {
  width: calc(100% - 20px);
  margin: 0 auto;
  padding: 0 !important;
  overflow: auto;
  position: absolute !important;
  z-index: 999000;
  border: 1px solid var(--grey-100);
  border-radius: 0 0 6px 6px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  max-height: 235px;
  margin-bottom: 100px;
  background-color: white;

  > :not(:last-child) {
    border-bottom: 1px solid var(--grey-200);
  }
}
