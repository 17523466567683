.cdk-overlay-container {
  bottom: 0;
  right: 0;
  width: auto;
  height: auto;
}

.cdk-overlay-dark-backdrop {
  //background: rgba(0,0,0,0.22);
  background: rgba(0, 0, 0, 0.52) !important;
}

.cdk-overlay-trasparent-backdrop {
  //background: rgba(0,0,0,0.22);
  background: rgba(0, 0, 0, 0);
}
.cdk-global-overlay-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  max-height: 100vh;
  height: 100%;
  z-index: 1000;
}

.cdk-global-overlay-wrapper.enable-click-cdk {
  pointer-events: none;
}

.cdk-drag-preview {
  .drag-handler {
    // margin-top: 27px;
    margin-right: 12px;
    padding: 5px;
  }
  .drag-delete-icon {
    opacity: 0;
  }
}

.cdk-drag-animating {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

.drop-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-overlay-blurry-backdrop {
  width: 100vw;
  height: 100vh;
  max-width: 100vw !important;
  position: fixed !important;
  left: 0;
  top: 0;
  margin: 0 !important;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto;
  z-index: 999999;
}
