@mixin mini-field($background-color) {
  width: fit-content;

  .mdc-floating-label {
    display: none;
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
    padding: 6px;
  }

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch {
    border: none;
  }

  .mdc-text-field {
    background-color: $background-color;
  }

  .mat-mdc-select-arrow-wrapper {
    margin-left: 6px;

    .mat-mdc-select-arrow {
      top: 1px;
      width: 9px !important;
    }
  }
}

@mixin no-icons() {
  app-svg-icon,
  .clear-button {
    display: none;
  }
}

.mini-field-blue-50 {
  @include mini-field(var(--blue-50));
}

.mat-mdc-input-element {
  font-size: 15px !important;
  font-weight: 400 !important;
}
