$find-work-theme: (
  "text-size": "14px",
  "covers-image-color": "#ffffff00",
  "cover-text-font": "inter",
  "cover-text-color": "#ffffff",
  "heading-text-font": "inter",
  "heading-text-color": "#1F2937",
  "descriptive-text-font": "inter",
  "descriptive-text-color": "#1F2937",
  "button-and-links-color": "#14b8a6",
);

:root {
  @each $name, $value in $find-work-theme {
    --#{$name}: #{$value};
  }
}
