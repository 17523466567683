.drag-handler {
  height: fit-content;
  margin-right: 12px;
  padding: 5px;
  cursor: move;
}

.drag-placeholder {
  min-height: 40px;
  background: var(--grey-200);
  border-radius: 10px;
  opacity: 0.4;
  transition: transform 100ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-delete-icon {
  height: fit-content;
  margin-left: 12px;
  cursor: pointer;
  opacity: 0;
  transition: opacity cubic-bezier(0.46, 0.03, 0.52, 0.96) 50ms;
}

.drop-list {
  > *:hover .drag-delete-icon {
    opacity: 1;
  }
}
