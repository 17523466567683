// override introjs styles ---------------------------------------------------------------

.introjs-tooltip {
  min-width: 330px !important;
  max-width: 330px !important;
  display: flex !important;
  flex-direction: column !important;
}

.introjs-tooltip {
  &.introjs-left {
    margin-right: 20px;
  }

  &.introjs-top-left-aligned {
    margin-bottom: 20px;
  }

  &.introjs-bottom-left-aligned {
    margin-top: 20px;
  }

  &.introjs-right {
    margin-left: 20px;
  }
}

.introjs-donebutton {
  padding: 16px 16px !important;
  border-radius: 10px !important;
  border: none !important;
  line-height: 1 !important;
  font-weight: 500;
  font-size: 14px;
  background-color: var(--blue-600);
  transition:
    background-color 300ms linear,
    transform 150ms ease-in;

  &:hover {
    transform: scale(1.05);
    background-color: var(--blue-600);
  }
}

.introjs-skipbutton {
  font-size: 12px;
  font-weight: 400;
  padding: 0px 20px;
  width: fit-content;
  line-height: 1.4;
  color: var(--grey-400);
  left: 0;
  right: none;
  text-decoration: underline;

  &:hover {
    color: var(--slate-600);
    text-decoration: underline;
  }
}

.introjs-nextbutton {
  padding: 0px 16px !important;
  border-radius: 10px !important;
  border: none !important;
  line-height: 1 !important;
  font-weight: 500;
  font-size: 14px;
  background-color: var(--blue-600) !important;
  transition:
    background-color 300ms linear,
    transform 150ms ease-in;
  margin-left: 10px !important;
  outline: none !important;
  box-shadow: none !important;

  svg {
    margin-left: 10px;
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    outline: none !important;
  }

  &:focus {
    outline: none !important;
  }
}

.introjs-prevbutton {
  padding: 0px 16px !important;
  border-radius: 10px !important;
  line-height: 1 !important;
  font-weight: 500;
  font-size: 14px;
  border: none !important;
  background-color: var(--white) !important;
  float: right !important;

  svg {
    path {
      fill: var(--neutral-400) !important;
    }
  }

  &:hover {
    transform: scale(1.05);
  }
}

.introjs-tooltipbuttons {
  border-top: none !important;
  display: flex !important;
  justify-content: flex-end !important;
  position: absolute !important;
  bottom: 50px !important;
  right: 0 !important;
}

.introjs-tooltip-header {
  order: 5;
  padding-top: 14px !important;
  margin-top: 10px !important;
}

.introjs-bullets {
  text-align: left !important;
  padding: 20px !important;

  a {
    &.active {
      background-color: var(--blue-600) !important;
    }
  }
}

.introjs-tooltipbuttons {
  border-top: none !important;
}

$z-index: 998;

.introjs-overlay {
  z-index: calc(#{$z-index} + 1) !important;
}

.introjs-helperLayer {
  z-index: calc(#{$z-index} + 1) !important;
}

.introjs-disableInteraction {
  z-index: calc(#{$z-index} + 1) !important;
}

.introjs-showElement {
  z-index: calc(#{$z-index} + 1) !important;
}

.introjs-tooltipReferenceLayer {
  z-index: calc(#{$z-index} + 1) !important;
}

.introjs-arrow {
  border: 15px solid transparent;
  position: absolute;

  &.top {
    border-bottom-color: white;
    top: -28px !important;
  }

  &.right {
    border-left-color: white;
    right: -28px !important;
  }

  &.left {
    border-right-color: white;
    left: -28px !important;
  }

  &.bottom {
    border-top-color: white;
    bottom: -28px !important;
  }
}

.done-label {
  padding: 12px 0px;
  display: flex;
  align-items: center;
}

.blue-border-overlay {
  box-shadow:
    var(--blue-600) 0px 0px 1px 2px,
    rgba(33, 33, 33, 0.7) 0px 0px 0px 5000px !important;
}

.blue-border-no-overlay {
  box-shadow: var(--blue-600) 0px 0px 1px 2px !important;
}

.black-border-overlay {
  box-shadow:
    var(--grey-800) 0px 0px 1px 2px,
    rgba(33, 33, 33, 0.7) 0px 0px 0px 5000px !important;
}

.black-border-no-overlay {
  box-shadow: var(--grey-800) 0px 0px 1px 2px !important;
}

.no-border-no-overlay {
  box-shadow:
    #ffffff00 0px 0px 1px 2px,
    rgba(33, 33, 33, 0) 0px 0px 0px 5000px !important;
}

.tour-title {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 600;
  color: var(--grey-800);
}

.tour-body {
  margin-top: 20px;
  font-size: 14px;
  color: var(--neutral-500);
}

.step-count {
  font-family: inter, "inter", sans-serif !important;
  font-size: 11px;
  color: var(--neutral-500);
  font-weight: 400 !important;

  .current {
    font-family: inter, "inter", sans-serif !important;
    font-size: 14px;
  }
}

.tour-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dark-bg {
  background-color: var(--grey-800);

  .introjs-tooltiptext .tour-body {
    margin-top: 20px;
    font-size: 14px;
    color: var(--grey-400);
  }

  .introjs-tooltiptext div h1 {
    color: var(--white);
  }

  .introjs-tooltiptext div p {
    color: var(--grey-400);
  }

  .introjs-tooltipbuttons .introjs-prevbutton {
    background-color: var(--neutral-700) !important;

    &:hover {
      transform: scale(1.05);
    }
  }

  .introjs-arrow {
    border: 15px solid transparent;
    position: absolute;

    &.top {
      border-bottom-color: var(--grey-800);
      top: -28px !important;
    }

    &.right {
      border-left-color: var(--grey-800);
      right: -28px !important;
    }

    &.left {
      border-right-color: var(--grey-800);
      left: -28px !important;
    }

    &.bottom {
      border-top-color: var(--grey-800);
      bottom: -28px !important;
    }
  }
}

.white-bg {
  background-color: var(--white);
}

.gradient-bg {
  background-image: linear-gradient(
    153deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(240, 255, 245, 1) 21%,
    rgba(255, 255, 255, 1) 50%
  );
}

.left-card-animate {
  @keyframes scaleInOutRightOrigin {
    0% {
      transform: scale(1);
      transform-origin: right;
    }

    50% {
      transform: scale(1.03);
      transform-origin: right;
    }

    100% {
      transform: scale(1);
      transform-origin: right;
    }
  }

  animation: scaleInOutRightOrigin 2s infinite ease-in-out;
}

.right-card-animate {
  @keyframes scaleInOutLeftOrigin {
    0% {
      transform: scale(1);
      transform-origin: left;
    }

    50% {
      transform: scale(1.03);
      transform-origin: left;
    }

    100% {
      transform: scale(1);
      transform-origin: left;
    }
  }

  animation: scaleInOutLeftOrigin 2s infinite ease-in-out;
}

.prevent-hide {
  .introjs-tooltip-header {
    display: none !important;
  }

  .introjs-tooltipbuttons {
    bottom: 0px !important;
  }
}
