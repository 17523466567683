@import "icons";

@mixin btn-hf-c($color) {
  color: $color;
  @include icon-color($color);
}

.mdc-button.mat-mdc-button {
  // SIZING

  &.btn-large {
    padding: 16px !important;
    min-width: 126px;
    font-size: 16px;
  }

  &.btn-mid {
    padding: 12px 16px !important;
    font-size: 14px;
  }

  &.btn-small {
    padding: 8px 10px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    font-size: 14px;
  }

  &.btn-icon {
    min-width: 36px;

    app-svg-icon,
    img {
      margin: 0;
    }
  }

  // TYPES
  &.btn-text {
    background-color: transparent;
    color: var(--grey-800);
    line-height: 1 !important;

    &-underline {
      @extend .btn-text;
      text-decoration: underline;
    }
  }

  &.btn-text-only {
    background-color: transparent !important;
    padding: 0px !important;
    color: var(--blue-600) !important;
    cursor: pointer;
    width: fit-content;

    &:hover {
      color: var(--blue-600) !important;
    }
  }

  &.btn-outlined {
    border: 1px solid var(--grey-800) !important;
    background-color: transparent;
    color: var(--grey-800);

    &-white {
      @extend .btn-outlined;
      border-color: white !important;
      color: white;
    }

    &-grey-200 {
      @extend .btn-outlined;
      border-color: var(--grey-200) !important;
    }

    &-blue-600 {
      @extend .btn-outlined;
      border-color: var(--blue-600) !important;
      color: var(--blue-600) !important;
    }

    &-blue-600-2 {
      @extend .btn-outlined-blue-600;
      border-width: 2px !important;
    }
  }

  // BACKGROUND
  &.btn-bk-black {
    background-color: var(--grey-800);
  }

  &.btn-bk-blue {
    background-color: var(--sky-800);
  }

  &.btn-bk-violet-600 {
    background-color: var(--violet-600);
  }

  &.btn-bk-red-500 {
    background-color: var(--red-500);
  }

  &.btn-bk-green {
    background-color: var(--teal-500);
  }

  &.btn-bk-white {
    background-color: var(--white);
    color: var(--grey-800);
  }

  &.btn-bk-yellow {
    background-color: var(--amber-500);
  }

  &.btn-bk-red-500 {
    background-color: var(--red-500);
  }

  &.btn-bk-zinc-200 {
    background-color: var(--zinc-200);
    color: var(--grey-800);
  }

  &.btn-bk-slate-50 {
    background-color: var(--slate-50);
    color: var(--grey-800);
  }

  &.btn-bk-transparent {
    background-color: var(--transparent);
  }

  &.btn-bk-sky-400 {
    background-color: var(--sky-400);
    color: white;
  }

  &.btn-bk-blue-600 {
    background-color: var(--blue-600);
    color: white;
  }

  &.btn-bk-blue-50 {
    background-color: var(--blue-50);
    color: var(--grey-800);
  }

  &.btn-bk-blue-gradient {
    background: linear-gradient(
      106deg,
      #2563eb 15.91%,
      #2563eb 99.31%,
      rgba(32, 33, 36, 0) 99.31%
    );
  }

  &.btn-bk-grey-200 {
    background-color: var(--grey-200);
    color: var(--grey-800);
  }

  &.btn-color-black {
    color: var(--grey-800);
  }

  //WIDTH
  &.btn-var-width {
    width: fit-content;
  }

  &.btn-full-width {
    width: 100%;
  }

  &.btn-fixed {
    width: 244px;
  }

  //SHAPE
  &.btn-circle {
    border-radius: 50% !important;

    app-svg-icon,
    img {
      width: 30px;
      height: 30px;
    }
  }

  &.btn-hf-blue:hover {
    @include btn-hf-c(var(--sky-800));
  }

  &.btn-hf-neutral-500:hover {
    @include btn-hf-c(var(--neutral-500));
  }

  &.btn-hbk-violet-600:hover {
    background-color: var(--violet-600) !important;

    &.btn-outlined {
      border-color: var(--violet-600) !important;
    }
  }

  &.close-dialog-button {
    right: calc(-100% + 22px) !important;
  }

  &:disabled,
  &[disabled] {
    background: none !important;
    background-color: var(--grey-200) !important;
    transform: none !important;
    color: white !important;
  }
}
