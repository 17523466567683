.mat-mdc-dialog-surface {
  padding: 40px 24px !important;
}

@media only screen and (max-width: $breakpoint-md) {
  .mat-mdc-dialog-surface {
    padding: 35px 24px !important;
  }
}

.mat-mdc-dialog-content {
  max-height: 50vh !important;
}

.full-width-dialog,
.dialog-no-padding,
.gradient-padded-dialog,
.padded-dialog,
.padded-dialog-s,
.padded-dialog-l,
.padded-dialog-alert,
.padded-dialog-file-view,
.loading-dialog {
  .mdc-dialog--closing {
    --mat-dialog-transition-duration: 3000ms !important;

    .mdc-dialog__container {
      transition: opacity 3000ms linear;
    }
  }

  .mat-mdc-dialog-surface {
    overflow-x: hidden;
    margin: 20px 0;
    position: relative;
    display: block;
    border-radius: 7px !important;
    background-color: #f0f1f7 !important;

    .close-dialog-button {
      cursor: pointer;
      position: relative;
      right: calc(-100% + 16px);
    }
  }
}

.full-width-dialog {
  width: 96vw;
}

.dialog {
  width: calc(630px + 2vw);
  max-width: 90vw !important;

  &-no-padding {
    .close-dialog-button {
      right: calc(-100% + 25px) !important;
      top: 4px;
    }

    .mat-mdc-dialog-surface {
      padding: 0 !important;
    }
  }
}

.padded-dialog,
.gradient-padded-dialog {
  @extend .dialog;

  .mat-mdc-dialog-surface {
    padding: 16px 16px 20px 20px !important;
  }
}

.padded-dialog-contract {
  width: calc(930px + 3vw) !important;

  .mat-mdc-dialog-surface {
    padding: 0 !important;
  }
}

.padded-dialog-service-builder-package {
  width: calc(720px + 2vw) !important;
}

.padded-dialog-s {
  min-width: calc(250px + 10vw) !important;
  max-width: calc(250px + 12vw) !important;

  .mat-mdc-dialog-surface {
    padding: calc(0.5vw + 10px) calc(0.5vw + 10px) calc(0.5vw + 6px) calc(1vw + 10px) !important;
  }
}

.dialog-l {
  width: calc(900px + 2vw) !important;
  max-width: 90vw !important;
  height: 90vh;

  .mat-mdc-dialog-surface {
    padding: 0 !important;
  }
}

.full-screen-dialog {
  width: 100vw;
  max-width: 100vw !important;
  height: 100%;
  max-height: 100%;

  .mat-dialog-container {
    padding: 10px !important;
    padding-bottom: 40px !important;
  }
}

.padded-dialog-alert {
  min-width: calc(250px + 10vw) !important;
  max-width: calc(250px + 22vw) !important;

  // width: min(90vw, calc(410px + 10vw)) !important;
  .mat-mdc-dialog-surface {
    padding-block: calc(0.5vw + 10px) !important;
    padding-inline: calc(1vw + 10px) !important;
  }
}

.padded-dialog-file-view {
  width: calc(900px + 2vw) !important;
  max-width: 90vw !important;

  // height: 100vh;
  // padding: 20px;
  .mat-mdc-dialog-surface {
    padding: 0 !important;
    margin: 0 !important;
  }
}

.loading-dialog {
  justify-content: center;

  .mat-mdc-dialog-surface {
    background: transparent !important;
    box-shadow: none !important;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
  .padded-dialog-contract {
    position: static;
    width: 100vw !important;
    max-width: 100vw !important;
    height: 100%;
    max-height: 100%;
    margin-left: 0px;
    margin-top: 0px;

    .mat-mdc-dialog-surface {
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0;
    }
  }
}
