$breakpoint-xs: 375px;
$breakpoint-sm: 576px;
$breakpoint-md: 767px;
$breakpoint-lg: 991px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;

$breakpoints: (
  "xs": $breakpoint-xs,
  "sm": $breakpoint-sm,
  "md": $breakpoint-md,
  "lg": $breakpoint-lg,
  "xl": $breakpoint-xl,
);

:root {
  --current-container-width: 1320px;

  @media only screen and (max-width: $breakpoint-xxl) {
    --current-container-width: 1140px;
  }
  @media only screen and (max-width: $breakpoint-xl) {
    --current-container-width: 960px;
  }
  @media only screen and (max-width: $breakpoint-lg) {
    --current-container-width: 720px;
  }
  @media only screen and (max-width: $breakpoint-md) {
    --current-container-width: 540px;
  }
}

.full-width-container {
  padding-block: 32px;
  margin-inline: 15px;
  @media only screen and (min-width: $breakpoint-sm) {
    margin-inline: 20px;
  }
  @media only screen and (min-width: $breakpoint-lg) {
    margin-inline: 60px;
  }
  @media only screen and (min-width: $breakpoint-xl) {
    margin-inline: 90px;
  }
}

.extra-full-width-container {
  margin-block: 32px;
  margin-inline: 15px;
  @media only screen and (min-width: $breakpoint-sm) {
    margin-inline: 20px;
  }
  @media only screen and (min-width: $breakpoint-lg) {
    margin-inline: 20px;
  }
  @media only screen and (min-width: $breakpoint-xl) {
    margin-inline: 30px;
  }
}

@media only screen and (max-width: $breakpoint-sm) {
}

@media only screen and (max-width: $breakpoint-xs) {
}
