button.sf-button,
button.sf-button-long,
a.sf-button,
a.sf-button-long {
  width: fit-content;
  border-radius: 7px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-width: 190px;
  padding: 5px 10px !important;
  font-size: calc(11px + 0.8vmin);
  color: white;
  background-color: var(--teal-500);
  text-align: center;
  font-weight: 500;
  border-width: 0;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

button.sf-button-long,
a.sf-a-long {
  padding: 5px 10px !important;
}

button.sf-button2,
button.sf-button2-m,
button.sf-button2-l,
a.sf-button2,
a.sf-button2-m,
a.sf-button2-l,
button.sf-button3 {
  width: fit-content;
  border-radius: 7px !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  min-width: 60px;
  font-size: 13px;
  color: white;
  background-color: var(--teal-500);
  text-align: center;
  font-weight: 500;
  border-width: 0;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: background-color 300ms linear;
}

button.sf-button3 {
  background-color: var(--amber-500);
}

button.sf-button2-m,
a.sf-button2-m {
  font-size: 23px;
  padding: 10px 28px 8px;

  @media only screen and (max-width: $breakpoint-md) and (orientation: portrait) {
    font-size: 20px;
    padding: 5px 24px !important;
  }

  @media only screen and (max-width: $breakpoint-xs) and (orientation: portrait) {
    font-size: 15px;
    padding: 0px 20px !important;
  }
}

button.sf-button2-l,
a.sf-a-l {
  font-size: 15px;
  min-width: 170px;
  height: 40px;

  @media only screen and (max-width: $breakpoint-md),
    screen and (orientation: portrait) {
    min-width: 150px;
    height: 35px;
    font-size: 13px;
  }
}
