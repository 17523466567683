@import "layout";

.custom-menu-options-wrapper {
  app-button {
    width: 100%;

    button {
      width: 100%;
    }
  }
}

.mat-mdc-select-value {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mat-mdc-option {
  font-size: 14px !important;
}

.mdc-list-item__primary-text {
  flex-shrink: 0;
}

.mat-mdc-option {
  line-height: 40px !important;
  height: 40px !important;
  color: var(--grey-800);
  font-size: 14px !important;
  border-radius: 10px !important;
  margin: 0px 5px 3px 5px !important;
  min-height: 40px !important;

  &.mat-mdc-option-active {
    background: var(--grey-100) !important;
  }
}

.mat-mdc-select-arrow {
  width: 14px !important;
  height: 14px !important;
  background-image: url(/assets/icons/design-system/chevrons-up-down.svg);
  background-origin: border-box;
  background-clip: content-box;
  background-size: contain;
  background-repeat: no-repeat;

  svg {
    display: none;
  }
}

.mat-mdc-select-panel {
  --mat-select-container-elevation-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.1),
    0 10px 8px 0 rgba(0, 0, 0, 0.04);
  border-radius: 12px !important;
  margin-top: 5px !important;
  padding: 5px 0px !important;
  border: 1px solid var(--grey-200);
}

.mat-select-value {
  padding: 12px 9px;
  padding-right: 2px;

  font-size: 14px;
  font-weight: 500;

  > .mat-select-placeholder {
    font-weight: 400;
  }
}

.mat-select {
  text-align: left;
  padding-block: 11px;

  .mat-select-arrow {
    border: none !important;
    width: 12px;
    height: 8px;

    background: transparent !important;
    background-color: var(--grey-800) !important;
    mask-image: url(../../assets/icons/design-system/chevrons-up-down.svg) !important;

    margin-top: 2px;
    padding: 0 !important;
    margin-right: 6px;
  }

  &.mat-select-disabled .mat-select-arrow {
    background-color: var(--neutral-400) !important;
  }
}

@media only screen and (max-width: $breakpoint-md) {
  .mat-select-value {
    padding: 6.9px 5px;
  }
}

.mat-mdc-option.mat-active,
.mat-mdc-option:hover {
  background: var(--grey-100) !important;
}

@media only screen and (max-width: $breakpoint-sm) {
  .mat-select-arrow {
    padding-right: 2px;
  }
}

.mat-mdc-optgroup-label {
  min-height: 40px !important;
  color: var(--neutral-400);
  font-size: 13px;
  font-weight: 600;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: var(--grey-800);
}
