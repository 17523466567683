@mixin checkbox($color: var(--neutral-400)) {
  ::ng-deep .mat-mdc-checkbox {
    --mdc-checkbox-selected-icon-color: #{$color} !important;
    --mdc-checkbox-selected-hover-icon-color: #{$color} !important;
    --mdc-checkbox-selected-pressed-icon-color: #{$color} !important;
    --mdc-checkbox-selected-focus-icon-color: #{$color} !important;
    --mdc-checkbox-selected-focus-state-layer-color: #{$color} !important;
    --mdc-checkbox-selected-hover-state-layer-color: var(--red-500) !important;
    --mdc-checkbox-selected-pressed-state-layer-color: #{$color} !important;
    --mdc-checkbox-unselected-icon-color: var(--grey-200) !important;

    --mdc-checkbox-unselected-hover-state-layer-opacity: 0 !important;
    --mdc-checkbox-unselected-focus-state-layer-opacity: 0 !important;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 0 !important;
    --mdc-checkbox-unselected-active-state-layer-opacity: 0 !important;
    --mdc-checkbox-unselected-hover-icon-color: #{$color} !important;

    --mdc-checkbox-selected-focus-state-layer-opacity: 0 !important;
    --mdc-checkbox-selected-active-state-layer-opacity: 0 !important;
    --mdc-checkbox-selected-hover-state-layer-opacity: 0 !important;
    --mdc-checkbox-selected-pressed-state-layer-opacity: 0 !important;
  }

  ::ng-deep .mat-mdc-checkbox .mdc-checkbox__background {
    border-width: 1px;
    border-radius: 4px;
  }

  ::ng-deep
    .mat-mdc-checkbox.mat-accent
    .mdc-checkbox__ripple
    .mat-ripple-element {
    opacity: 0 !important;
  }

  ::ng-deep .mat-mdc-checkbox-checked.mat-accent .mdc-checkbox__background,
  .mat-checkbox-indeterminate.mat-accent .mdc-checkbox__background {
    background-color: $color;
    border-color: $color;

    .mdc-checkbox__checkmark {
      margin-top: -1px;

      .mdc-checkbox__checkmark-path {
        stroke-width: 4px;
      }
    }
  }
}

.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-mdc-checkbox:active:not(.mat-mdc-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background: black;
}

.mdc-checkbox
  .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not(
    [data-indeterminate="true"]
  )
  ~ .mdc-checkbox__background {
  background-color: white !important;
}

// .mdc-checkbox:not(.mdc-checkbox--selected):hover {
//   --mdc-checkbox-unselected-hover-state-layer-opacity: 0; /* Remove hover layer */
//   border: 2px solid var(--red-500);
// }
